/* @import url(//fonts.googleapis.com/earlyaccess/notosanskr.css); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap'); */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format('woff2'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format('woff'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),
        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format('woff2'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff'), url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format('opentype');
}

window {
    behavior: smooth;
    -webkit-behavior: smooth;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important
}

button {
    font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
    font-weight: 500;

}

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

* {
    box-sizing: border-box;
    word-break: break-word;
}

table {
    border-spacing: 0;
}

img {
    border: none !important;
}

button:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.number {
    font-family: "Montserrat";
    font-weight: bold !important;
}

.p-l-10 {
    padding-left: 20px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-5 {
    padding-top: 5px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-30 {
    margin-top: 30px;
}


.m-b-30 {
    margin-bottom: 30px !important;
}


.subscription_btn {
    margin: 0px 0px 80px 0px;
}

.survey {
    /* background-image: url('/asset/image/survey/button_bg.jpg');
 background-repeat: repeat-y;
 background-size: 100% 100%; */
    background: linear-gradient(-45deg, #5e3cf7, #8142fe);
    margin: 0px;
}

.main {
    margin-left: 0px;
    margin-bottom: 0px;
}

.settings {
    max-width: 786px;
}

.first-section {
    position: relative;
}

.left-img {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    width: 600px;

}

.right-img {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;
    width: 500px;
}

@media(max-width: 700px) {
    .main {
        padding: 0px;
    }

    .left-img {
        width: 100%;
        min-width: 320px;
    }

    .right-img {
        width: 300px;
    }
}

.tooltip-left {
    left: 10px !important;
}

@media(max-width: 1260px) {
    .main {
        padding: 0px;
    }

    /* .left-img {
        width:400px;
    }

    .right-img{
        width: 300px;
    } */
}